function makeFormat(width, height){
  canvasSizeX = width;
  canvasSizeY = height;
  layoutFormat = 'facebook';
  $('#mySlim').slim().slimDestroy();
  makeCropper(width, height);
  makeLayout();
}

function changeFormat(width, height, format){
  canvasSizeX = width;
  canvasSizeY = height;
  layoutFormat = format;
  
  var originalPhotoSrc = $('#original-photo').attr('src');
  $('#mySlim').slim().slimDestroy();
  $('#original-photo').attr('src', originalPhotoSrc);
  makeCropper(width, height);
  // makeLayout();
}

$('.js-format-block-box').click(function(){
  $('.js-format-block-box').removeClass('active');
  $(this).addClass('active');
});
