$('.js-menu-mobile-button').click(function(){
  $('.menu-mobile-box').fadeToggle();
});


// Mostrando modal al seccionar una foto
// function readURL(input) {
//     if (input.files && input.files[0]) {
//         var reader = new FileReader();
//         reader.onload = function (e) {
//             // $('#original-photo').attr('src', e.target.result);
//         }
//         reader.readAsDataURL(input.files[0]);
//     }
// }

// $(document).on('change', '#upload-photo', function(){
//     // readURL(this);
//     // $('.js-preloader-photo').fadeOut();
//     // setTimeout(function(){
//     //   $('.js-btn-edit-photo').click();
//     //   var originalPhotoSrc = $('.slim-crop').attr('src');
//     //   // console.log(originalPhotoSrc);
//     //   $('#original-photo').attr('src', originalPhotoSrc);
//     // }, 500);
// });

$(document).on('change', '#upload-photo', function(e){
  loadImage(
      e.target.files[0],
      function (img) {
          // document.body.appendChild(img);
          $('.js-preloader-photo').fadeOut();
          var originalPhotoSrc = img.toDataURL();
          $('#original-photo').attr('src', originalPhotoSrc);
      },
      {
        maxWidth:1600,
        maxHeight: 1600,
        canvas:true,
        orientation:true,

      } // Options
  );
});


// $(document).ready(function(){
//   $('.modal').modal('show');
// });


// $('#selected-photo').one('load', function(){
//   $('.js-btn-edit-photo').click();
//   console.log($('.slim-crop'));
//   var originalPhotoSrc = $('.slim-crop').attr('src');
//   $('#original-photo').attr('src', originalPhotoSrc);
// });
