// Iniciando Canvas
function initCanvas(){
  var c=document.getElementById("js-photo-canvas");
  var ctx=c.getContext("2d");
  c.width = canvasSizeX;
  c.height = canvasSizeY;

  ctx.clearRect(0, 0, c.width, c.height);

  // Add Layout
  var img=document.getElementById("layout");
  ctx.drawImage(img,0,0, canvasSizeX, canvasSizeY);
}

// Limpiando Canvas
function cleanCanvas(){
  var c=document.getElementById("js-photo-canvas");
  var ctx=c.getContext("2d");
  c.width = canvasSizeX;
  c.height = canvasSizeY;
  ctx.clearRect(0, 0, c.width, c.height);
}



// Función para actualizar foto y aplicar el Layout
function makeLayout() {
    // Canvas - Especificaciones
    var c=document.getElementById("js-photo-canvas");
    var ctx=c.getContext("2d");

    ctx.clearRect(0, 0, c.width, c.height);
    c.width = canvasSizeX;
    c.height = canvasSizeY;


    // Calculando medidas de la foto selccionada
    // var photo=document.getElementById("selected-photo");
    var photo=$('#selected-photo')[0];
    ctx.drawImage(photo,0,0,canvasSizeX,canvasSizeY);

    // Add Layout
    // console.log($('#js-layout-'+layoutFormat));
    // var img=document.getElementById("layout");
    // var img=document.getElementById("layout");
    var img = $('#js-layout-'+layoutFormat)[0];
    ctx.drawImage(img,0,0, canvasSizeX, canvasSizeY);

    // Export to Canvas
    var final_img    = c.toDataURL("image/png");
    $('#save-photo').attr('href', final_img);

    // Hide Loader
    $('.js-photo-canvas-loader').fadeOut();
};


// Función para inicializar el Canvas
$(window).on('load', function(){
  initCanvas();
});

function exportLayout(filename_src){
  $('.js-change-layout .bg-photo').css("background-image", "url("+filename_src+")");
  $('#selected-photo').one('load', function(){
      makeLayout();
  });
}


// Change Layout
$('.js-change-layout').click(function(){

  $('.js-photo-canvas-loader').fadeIn();

  var layout_number = $(this).data('layout');
  var slim_final_image = $('.slim input + input').val();
  $('#js-layout-'+layoutFormat).attr('src', '/img/layout/'+layoutFormat+'/'+ layout_number +'.png');

  $('#js-layout-'+layoutFormat).one('load', function(){
    makeLayout();
  });

  $('.js-change-layout').removeClass('active');
  $(this).addClass('active');

});
