function makeCropper(cropperWidth, cropperHeight){
  var $cropper = $('#mySlim').slim({
    size:{
      width:cropperWidth,
      height:cropperHeight
    },
    ratio: canvasSizeX+':'+canvasSizeY,
    service: '/async.php',
    edit:true,
    remove:false,
    push: true,
    download: true,
    label: 'Haz click aquí para subir tu foto',
    onInit: function(slim){

    },

    onSave: function(data, cb){
      $('#selected-photo').attr('src', data.output.image);
      exportLayout(data.output.image);
    },
  });

}


$(document).ready(function(){
  makeCropper(canvasSizeX, canvasSizeY);
});

// Slim Edit Custom
$('.js-btn-edit-photo').click(function(){
  $('.slim-btn-edit').click();
});
